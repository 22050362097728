/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import UserManager from 'src/business/UserBusinessManager';
import LoginComponent from 'src/components/Login/LoginComponent';
import { LoginFormData } from 'src/types/Login';
import { useLoginMutation } from 'src/hooks/useLogin';
import { Section } from 'src/ui/GridSystem/GridSystem';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
  },
}));

const Login = () => {
  const styles = useStyles();
  const loginMutation = useLoginMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(UserManager.loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmitHandler = (values: LoginFormData): void => {
    loginMutation.mutate(values);
  };

  return (
    <Section className={styles.root}>
      <LoginComponent
        control={control}
        onSubmit={handleSubmit(onSubmitHandler)}
        errors={errors}
        apiError={loginMutation.isError}
        submitButtonDisabled={loginMutation.isLoading}
      />
    </Section>
  );
};

export default Login;
