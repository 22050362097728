import { AxiosError, AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import UserBusinessManager from 'src/business/UserBusinessManager';
import { login, logout as logoutHTTP } from 'src/http/user/user';
import { LOBBY, LOGIN as LOGIN_ROUTE } from 'src/routes/Routes';
import {
  LoginFormData,
  LoginRequestResultData,
  QUERY_LOGIN_KEY,
} from 'src/types/Login';
import { ProfileNameEnum } from 'src/types/User';

export const useLoginMutation = (): UseMutationResult<
  AxiosResponse<LoginRequestResultData>,
  AxiosError,
  LoginFormData
> => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const loginMutation = useMutation<
    AxiosResponse<LoginRequestResultData>,
    AxiosError,
    LoginFormData
  >(login, {
    retry: 0,
    onMutate: async () => {
      await queryClient.cancelQueries(QUERY_LOGIN_KEY);
      queryClient.setQueryData(QUERY_LOGIN_KEY, { user: null });
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(QUERY_LOGIN_KEY, data);
      // If the user has GUEST profile, force it to go to lobby page:
      if (data.profile === ProfileNameEnum.GUEST) {
        router.push(LOBBY);
        return;
      }

      const redirectUrl = UserBusinessManager.shouldRedirectAfterLogin(router);
      if (redirectUrl) {
        router.push(redirectUrl as string);
      }
    },
    onError: () => {
      queryClient.setQueryData(QUERY_LOGIN_KEY, null);
    },
  });

  return loginMutation;
};

type UseLogoutHook = () => Promise<void>;
export const useLogout = (redirect = ''): UseLogoutHook => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const urlSuffix = redirect || router.asPath || null;
  const redirectQueryString = urlSuffix
    ? `?redirect=${encodeURIComponent(urlSuffix)}`
    : '';
  const url = `${LOGIN_ROUTE}${redirectQueryString}`;

  async function backendLogout() {
    try {
      await queryClient.removeQueries(QUERY_LOGIN_KEY);
      await logoutHTTP();
    } catch (_) {
      // there's nothing UI can do if backend fails logout.
    }
    // Push the user to the login page whatever happens before
    router.push(url);
  }

  return backendLogout;
};
