import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Logo from './lemme-logo-full.svg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '208px',
    },
  }),
);

interface FullLogProps {
  classes?: unknown;
}

const FullLogo = (props: FullLogProps) => {
  const styles = useStyles(props);
  return <img src={Logo} className={styles.root} />;
};

export default FullLogo;
