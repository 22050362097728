import { Box, Card, Theme, Typography } from '@mui/material';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import { FORGOT_PASSWORD, REGISTER } from 'src/routes/Routes';
import theme from 'src/theme/theme';
import { GeneralFormProps } from 'src/types/FormUtils';
import { LoginFormData } from 'src/types/Login';
import CircularProgressButton from 'src/ui/CircularProgressButton/CircularProgressButton';
import Link from 'src/ui/Link/Link';
import FullLogo from 'src/ui/Logo/FullLogo';
import TextField from 'src/ui/TextField/TextField';

const useStyles = makeStyles(({ spacing, breakpoints, palette }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '20px',
      [breakpoints.up('sm')]: {
        width: '375px',
        padding: `${spacing(5)} ${spacing(4)}`,
      },
      [breakpoints.up('lg')]: {
        width: '425px',
      },
      zIndex: 1,
      border: 'none',
    },
    title: {
      textAlign: 'center',
      marginBottom: spacing(4),
      color: palette.primary.main,
    },
    forgotPassword: {
      color: palette.grey[700],
    },
    submitButton: {
      width: 286,
      height: 45,
    },
    field: {
      display: 'flex',
      flex: '1 1 100%',
      marginBottom: spacing(2),
    },
    areYouNew: {
      display: 'inline-block',
      color: palette.grey[700],
      textDecoration: 'underline',
    },
    signUp: {
      display: 'inline-block',
      marginLeft: spacing(1),
      marginTop: 2,
      color: palette.text.primary,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    errorMessage: {
      background: palette.error.main,
      color: palette.error.contrastText,
      height: 40,
      borderRadius: 4,
      marginBottom: spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const LoginForm = ({
  control,
  onSubmit,
  apiError = false,
  submitButtonDisabled = false,
}: GeneralFormProps<LoginFormData>) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Card elevation={0} className={styles.root}>
      <Box
        justifyContent="center"
        display="flex"
        minHeight={56}
        marginTop={theme.spacing(2)}
        marginBottom={theme.spacing(4)}
      >
        <FullLogo />
      </Box>
      <Typography variant="h4" component="h1" className={styles.title}>
        {t('login.title')}
      </Typography>
      {apiError && (
        <Box className={styles.errorMessage} data-testid="login-error-message">
          <Typography variant="body1">{t('login.login_failed')}</Typography>
        </Box>
      )}
      <form onSubmit={onSubmit}>
        <TextField<LoginFormData>
          className={styles.field}
          control={control}
          name="email"
          label={t('global.email')}
        />
        <TextField<LoginFormData>
          className={styles.field}
          control={control}
          name="password"
          label={t('global.password')}
          type="password"
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop={theme.spacing(3)}
        >
          <Link href={FORGOT_PASSWORD}>
            <Typography variant="caption" className={styles.forgotPassword}>
              {t('login.forgot_password')}
            </Typography>
          </Link>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={theme.spacing(4)}
        >
          <CircularProgressButton
            size="large"
            className={styles.submitButton}
            type="submit"
            disabled={submitButtonDisabled}
          >
            {t('login.submit_button')}
          </CircularProgressButton>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={theme.spacing(4)}
        >
          <Link href={REGISTER}>
            <Typography className={styles.areYouNew}>
              {t('login.register_btn')}
            </Typography>
          </Link>
        </Box>
      </form>
    </Card>
  );
};

export default LoginForm;
