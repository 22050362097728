import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { FOOTER_HEIGHT } from 'src/config/config';

export interface ExtendedBoxProps extends BoxProps {
  name?: string;
  marginTop?: string | number;
  maxWidth?: string | number;
}

export interface ImgBackgroundSectionProps extends ExtendedBoxProps {
  backgroundImage: string;
}

export const Section = styled(Box)<ExtendedBoxProps>(
  ({ marginTop = 0, maxWidth = 'unset' }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop,
    maxWidth,
    width: '100%',
    padding: 0,
    minHeight: `calc(100vh - ${FOOTER_HEIGHT + 52}px)`,
    justifyContent: 'center',
  }),
);
Section.displayName = 'Section';

export const ImgBackgroundSection = styled(Section)<ImgBackgroundSectionProps>(
  ({ backgroundImage }) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundImage})`,
  }),
);

interface SectionContentProps extends BoxProps {
  alignItems?: string;
  marginTop?: number | string;
  paddingLeft?: number | string;
  paddingRight: number | string;
  maxWidth: number | string;
  minHeight: number | string;
}
export const SectionContent = styled(Box)<SectionContentProps>(
  ({
    alignItems = 'flex-start',
    marginTop = 0,
    paddingLeft = undefined,
    paddingRight = undefined,
    maxWidth = undefined,
    minHeight = 'auto',
  }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems,
    marginTop,
    paddingLeft: paddingLeft || 0,
    paddingRight: paddingRight || 0,
    minHeight,
    maxWidth,
  }),
);
SectionContent.displayName = 'SectionContent';
