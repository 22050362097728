import NextLink from 'next/link';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Children } from 'src/@types';
import Typography from '@mui/material/Typography';

interface LinkProps {
  href: string;
  classes?: unknown;
  underline?: 'none' | 'hover' | 'always';
  children: Children;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const Link = (props: LinkProps) => {
  const styles = useStyles(props);
  const { children, href } = props;
  return (
    <NextLink href={href}>
      <Typography component="span" classes={styles}>
        {children}
      </Typography>
    </NextLink>
  );
};

export default Link;
