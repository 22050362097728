import Launching from './Launching';

export enum ProfileNameEnum {
  LEMME_ADMIN = 'LEMME_ADMIN',
  LEMME_STAFF = 'LEMME_STAFF',
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  FREELANCER = 'FREELANCER',
  VIEWER = 'VIEWER',
  GUEST = 'GUEST',
}
export interface Profile {
  name: ProfileNameEnum;
  description: string;
  id: string;
}
export default interface User {
  id?: string;
  is_active?: boolean;
  is_admin?: boolean;
  name?: string;
  email?: string;
  launchings?: Launching[];
  profile: NamedValueModel<ProfileNameEnum>;
}
